import "./style.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { useTranslation } from "react-i18next";

function Cpu() {
  const { t, i18n } = useTranslation("common");
  return (
    <div id="apuestas" className="apuestas">
      <div className="titlesContainer">
        <h2>
          <div>{t("apuestas.title1")}</div>
          <div>{t("apuestas.title2")}</div>
        </h2>
      </div>
      <div className="lineContainer">
        <div className="lineTitle" />
      </div>
      <div className="">
        <p className="description" style={{ textAlign: "center" }}>
          {t("apuestas.description1")}
        </p>
      </div>
    </div>
  );
}

export default Cpu;
