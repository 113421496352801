import "./style.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { useTranslation } from "react-i18next";

const apuestas =
  "https://elasticbeanstalk-us-east-2-692605901339.s3.us-east-2.amazonaws.com/img/img/apuestas.png";

function Cpu() {
  const { t, i18n } = useTranslation("common");
  return (
    <div id="prediccion" className="prediccion">
      <div className="titlesContainer">
        <h2>
          <div>{t("prediccion.title1")}</div>
          <div>{t("prediccion.title2")}</div>
        </h2>
      </div>
      <div className="lineContainer">
        <div className="lineTitle" />
      </div>
      <div className="">
        <p className="description" style={{ textAlign: "center" }}>
          {t("prediccion.description1")}
        </p>
        <img
          src={apuestas}
          style={{
            height: "32rem",
            margin: "auto",
            display: "flex",
            marginTop: "3rem  ",
          }}
        ></img>
      </div>
    </div>
  );
}

export default Cpu;
