import "./style.scss";

import { Card } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";

const team =
  "https://elasticbeanstalk-us-east-2-692605901339.s3.us-east-2.amazonaws.com/img/img/TEAM.svg";
const Luis =
  "https://elasticbeanstalk-us-east-2-692605901339.s3.us-east-2.amazonaws.com/img/img/team/Luis Oria.jpeg";
const Alejandro =
  "https://elasticbeanstalk-us-east-2-692605901339.s3.us-east-2.amazonaws.com/img/img/team/Alejandro Cosse.jpeg";
const Elbin =
  "https://elasticbeanstalk-us-east-2-692605901339.s3.us-east-2.amazonaws.com/img/img/team/Elbin Brito.jpeg";
const LuisA =
  "https://elasticbeanstalk-us-east-2-692605901339.s3.us-east-2.amazonaws.com/img/img/team/Luis Alvarez.jpeg";
const Virginia =
  "https://elasticbeanstalk-us-east-2-692605901339.s3.us-east-2.amazonaws.com/img/img/team/Virginia Moncada.jpeg";
const Yeremi =
  "https://elasticbeanstalk-us-east-2-692605901339.s3.us-east-2.amazonaws.com/img/img/team/Yeremi Martínez.jpeg";
const Pedro =
  "https://elasticbeanstalk-us-east-2-692605901339.s3.us-east-2.amazonaws.com/img/img/team/pedro.jpeg";

const Joel =
  "https://elasticbeanstalk-us-east-2-692605901339.s3.us-east-2.amazonaws.com/img/img/joelc.jpg";

const { Meta } = Card;
function Team() {
  const { t, i18n } = useTranslation("common");
  return (
    <div id="team" className="team">
      <div className="titlesContainer">
        <img src={team}></img>
      </div>
      <div className="lineContainer">
        <div className="lineTitle" />
      </div>
      <div className="cardsContainer">
        <Card
          style={{ width: 240 }}
          cover={<img className="imgTeam" alt="example" src={Luis} />}
        >
          <Meta className="dataCard" title="Luis Oria" />
          <Meta className="" description="CEO Funder" />
          <a
            href="https://www.linkedin.com/in/luis-oria-6a59b5198"
            className="linksTeam"
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a href="https://www.instagram.com/p/CXjnsJIv39T/?utm_medium=copy_link">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </Card>
        ,
        <Card
          style={{ width: 240 }}
          cover={<img className="imgTeam" alt="example" src={Alejandro} />}
        >
          <Meta className="dataCard" title="Alejandro Cosse" />
          <Meta className="" description="Manager Social Media" />
          <a
            href="https://www.linkedin.com/in/alejandro-cosse-oria-a0693a126"
            className="linksTeam"
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a href="https://instagram.com/alejandrcosse?utm_medium=copy_link">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </Card>
        ,
        <Card
          style={{ width: 240 }}
          cover={<img className="imgTeam" alt="example" src={Yeremi} />}
        >
          <Meta className="dataCard" title="Jeremy Martinez" />
          <Meta className="" description="Video game developer" />
          <a
            href="http://linkedin.com/in/jeremy-j-martínez-93525220a"
            className="linksTeam"
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a href="https://instagram.com/jmtz_roboteam?utm_medium=copy_link">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </Card>
        ,
      </div>
      <div className="cardsContainer">
        <Card
          style={{ width: 240 }}
          cover={<img className="imgTeam" alt="example" src={LuisA} />}
        >
          <Meta className="dataCard" title="Luis Alvarez" />
          <Meta className="" description="Technology System" />
          <a
            href="https://www.linkedin.com/in/luis-alfredo-alvarez-1a6a93228"
            className="linksTeam"
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a href="https://instagram.com/otroluisalfredo?utm_medium=copy_link">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </Card>
        ,
        <Card
          style={{ width: 240 }}
          cover={<img className="imgTeam" alt="example" src={Virginia} />}
        >
          <Meta className="dataCard" title="Virginia Clark" />
          <Meta className="" description="Chief Design" />
          <a
            href="https://www.linkedin.com/in/rosa-clark-883211228"
            className="linksTeam"
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a href="https://www.instagram.com/p/CXEjVZZFdSn/?utm_medium=share_sheet">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </Card>
        ,
        <Card
          style={{ width: 240 }}
          cover={<img className="imgTeam" alt="example" src={Elbin} />}
        >
          <Meta className="dataCard" title="Elbin Brito" />
          <Meta className="" description="Art Concepts" />
          <a
            href="https://www.linkedin.com/in/elbin-elbin-brito-66a6b648"
            className="linksTeam"
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a href="https://www.instagram.com/p/CXhfRpHlrO1/?utm_medium=copy_link">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </Card>
        ,
      </div>
      <div className="cardsContainer">
        <Card
          style={{ width: 240 }}
          cover={<img className="imgTeam" alt="example" src={Pedro} />}
        >
          <Meta className="dataCard" title="Pedro Hernandez" />
          <Meta className="" description="Web master" />
          <a
            href="http://linkedin.com/in/peter-hernandez-652b8649"
            className="linksTeam"
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a href="https://www.instagram.com/p/CV6UDDFs8jL/?utm_medium=copy_link">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </Card>
        ,
        <Card
          style={{ width: 240 }}
          cover={<img className="imgTeam" alt="example" src={Joel} />}
        >
          <Meta className="dataCard" title="Joel C (Moebius)" />
          <Meta className="" description="Programador Senior" />
          <a href="https://www.linkedin.com/" className="linksTeam">
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a href="https://www.instagram.com/">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </Card>
        ,
      </div>
    </div>
  );
}

export default Team;
