import "./style.scss";

const auditors =
  "https://elasticbeanstalk-us-east-2-692605901339.s3.us-east-2.amazonaws.com/img/img/rdauditors.png";
const badgeAws =
  "https://elasticbeanstalk-us-east-2-692605901339.s3.us-east-2.amazonaws.com/img/img/aws.jpeg";
const badgeBinance =
  "https://elasticbeanstalk-us-east-2-692605901339.s3.us-east-2.amazonaws.com/img/img/binance.svg";
const badgeWeb3 =
  "https://elasticbeanstalk-us-east-2-692605901339.s3.us-east-2.amazonaws.com/img/img/web3.jpg";
const badgeWebgl =
  "https://elasticbeanstalk-us-east-2-692605901339.s3.us-east-2.amazonaws.com/img/img/webgl.jpg";
const badgeUnity =
  "https://elasticbeanstalk-us-east-2-692605901339.s3.us-east-2.amazonaws.com/img/img/unity.svg";
const cryptomarketing =
  "https://elasticbeanstalk-us-east-2-692605901339.s3.us-east-2.amazonaws.com/img/img/cryptomarket.jpeg";
const partnership =
  "https://elasticbeanstalk-us-east-2-692605901339.s3.us-east-2.amazonaws.com/img/img/partnership.png";

const badgeDaddy =
  "https://elasticbeanstalk-us-east-2-692605901339.s3.us-east-2.amazonaws.com/img/img/sponsors/daddy.jpeg";
const babylon =
  "https://elasticbeanstalk-us-east-2-692605901339.s3.us-east-2.amazonaws.com/img/img/sponsors/babylon.jpg";
const colyseus =
  "https://elasticbeanstalk-us-east-2-692605901339.s3.us-east-2.amazonaws.com/img/img/sponsors/colyseus.jpg";

function Partnership() {
  return (
    <div id="partnership" className="partnership">
      <div className="titlesContainer">
        <img src={partnership}></img>
      </div>
      <div className="lineContainer">
        <div className="lineTitle" />
      </div>
      <div className="buttonContainer">
        <a href="https://unity.com/es" target="_blank">
          <img className="badgesImg" src={badgeUnity} alt="logo" />
        </a>
        <a href="https://www.binance.org/en/smartChain" target="_blank">
          <img className="badgesImg" src={badgeBinance} alt="logo" />
        </a>
        <a href="https://web3.foundation/" target="_blank">
          <img width="96px" className="badgesImg" src={badgeWeb3} alt="logo" />
        </a>
        <a href="https://get.webgl.org/" target="_blank">
          <img width="80px" className="badgesImg" src={badgeWebgl} alt="logo" />
        </a>
        <a href="https://cryptomarketing.cl/" target="_blank">
          <img width="160px" className="badgesImg" src={babylon} alt="logo" />
        </a>
        <a href="https://cryptomarketing.cl/" target="_blank">
          <img width="140px" className="badgesImg" src={colyseus} alt="logo" />
        </a>
        <a
          href="https://www.godaddy.com/es/hosting/wordpress-hosting"
          target="_blank"
        >
          <img className="badgesImg wDaddy" src={badgeDaddy} alt="logo" />
        </a>
        <a
          href="https://aws.amazon.com/managed-blockchain/?nc2=h_mo"
          target="_blank"
        >
          <img className="badgesImg wDaddy" src={badgeAws} alt="logo" />
        </a>
        <a href="https://www.rdauditors.com/" target="_blank">
          <img className="badgesImg wDaddy" src={auditors} alt="logo" />
        </a>
        <a href="https://cryptomarketing.cl/" target="_blank">
          <img className="badgesImg wDaddy" src={cryptomarketing} alt="logo" />
        </a>
      </div>
    </div>
  );
}

export default Partnership;
