import "./style.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Carousel } from "react-responsive-carousel";
//import cpu from "../../img/CPUS.svg";
// import green from "../../img/green.gif";
// import pink from "../../img/pink.gif";
// import red from "../../img/red.gif";
import { useTranslation } from "react-i18next";
// import white from "../../img/white.gif";
// import yellow from "../../img/yellow.gif";

const blue =
  "https://elasticbeanstalk-us-east-2-692605901339.s3.us-east-2.amazonaws.com/img/img/blue.gif";
const cpu =
  "https://elasticbeanstalk-us-east-2-692605901339.s3.us-east-2.amazonaws.com/img/img/CPUS.svg";
const green =
  "https://elasticbeanstalk-us-east-2-692605901339.s3.us-east-2.amazonaws.com/img/img/green.gif";
const pink =
  "https://elasticbeanstalk-us-east-2-692605901339.s3.us-east-2.amazonaws.com/img/img/pink.gifg";
const red =
  "https://elasticbeanstalk-us-east-2-692605901339.s3.us-east-2.amazonaws.com/img/img/red.gif";
const white =
  "https://elasticbeanstalk-us-east-2-692605901339.s3.us-east-2.amazonaws.com/img/img/white.gif";
const yellow =
  "https://elasticbeanstalk-us-east-2-692605901339.s3.us-east-2.amazonaws.com/img/img/yellow.gif";

function Cpu() {
  const { t, i18n } = useTranslation("common");
  return (
    <div id="cpu" className="cpu">
      <div className="titlesContainer">
        <img src={cpu} alt="cpu"></img>
      </div>
      <div className="lineContainer">
        <div className="lineTitle" />
      </div>
      <div className="carrouselContainer">
        <Carousel
          showThumbs={false}
          showStatus={false}
          width="auto"
          swipeable={true}
          centerSlidePercentage="100%"
        >
          <div className="itemsCarrousel">
            <div className="nftContainer">
              <img className="nft" src={red} alt="nft" />
            </div>
            <div className="textContainerNft">
              <p className="robotsName">
                {t("cpu.red.name")} {t("cpu.control")} 6 Robots
              </p>
              <p className="robotsSerie">{t("cpu.red.serie")}</p>
              <p className="robotsDescription">{t("cpu.red.description1")}</p>
              <p className="robotsDescription2">{t("cpu.red.description2")}</p>
              <div className="buttonBuy">
                <a href={t("cpu.red.link")}>
                  <p>{t("cpu.red.button")}</p>
                </a>
              </div>
            </div>
          </div>
          <div className="itemsCarrousel">
            <div className="nftContainer">
              <img loading="lazy" className="nft" src={yellow} alt="nft" />
            </div>
            <div className="textContainerNft">
              <p className="robotsName">
                {t("cpu.yellow.name")} {t("cpu.control")} 5 Robots
              </p>
              <p className="robotsSerie">{t("cpu.yellow.serie")}</p>
              <p className="robotsDescription">
                {t("cpu.yellow.description1")}
              </p>
              <p className="robotsDescription2">
                {t("cpu.yellow.description2")}
              </p>
              <div className="buttonBuy">
                <a href={t("cpu.yellow.link")}>
                  <p>{t("cpu.yellow.button")}</p>
                </a>
              </div>
            </div>
          </div>
          <div className="itemsCarrousel">
            <div className="nftContainer">
              <img loading="lazy" className="nft" src={blue} alt="nft" />
            </div>
            <div className="textContainerNft">
              <p className="robotsName">
                {t("cpu.blue.name")} {t("cpu.control")} 4 Robots
              </p>
              <p className="robotsSerie">{t("cpu.blue.serie")}</p>
              <p className="robotsDescription">{t("cpu.blue.description1")}</p>
              <p className="robotsDescription2">{t("cpu.blue.description2")}</p>
              <div className="buttonBuy">
                <a href={t("cpu.blue.link")}>
                  <p>{t("cpu.blue.button")}</p>
                </a>
              </div>
            </div>
          </div>
          <div className="itemsCarrousel">
            <div className="nftContainer">
              <img loading="lazy" className="nft" src={pink} alt="nft" />
            </div>
            <div className="textContainerNft">
              <p className="robotsName">
                {t("cpu.pink.name")} {t("cpu.control")} 3 Robots
              </p>
              <p className="robotsSerie">{t("cpu.pink.serie")}</p>
              <p className="robotsDescription">{t("cpu.pink.description1")}</p>
              <p className="robotsDescription2">{t("cpu.pink.description2")}</p>
              <div className="buttonBuy">
                <a href={t("cpu.pink.link")}>
                  <p>{t("cpu.pink.button")}</p>
                </a>
              </div>
            </div>
          </div>
          <div className="itemsCarrousel">
            <div className="nftContainer">
              <img loading="lazy" className="nft" src={white} alt="nft" />
            </div>
            <div className="textContainerNft">
              <p className="robotsName">
                {t("cpu.white.name")} {t("cpu.control")} 2 Robots
              </p>
              <p className="robotsSerie">{t("cpu.white.serie")}</p>
              <p className="robotsDescription">{t("cpu.white.description1")}</p>
              <p className="robotsDescription2">
                {t("cpu.white.description2")}
              </p>
              <div className="buttonBuy">
                <a href={t("cpu.white.link")}>
                  <p>{t("cpu.white.button")}</p>
                </a>
              </div>
            </div>
          </div>
          <div className="itemsCarrousel">
            <img loading="lazy" className="nft" src={green} alt="nft" />
            <div className="textContainerNft">
              <p className="robotsName">
                {t("cpu.green.name")} {t("cpu.control")} 1 Robots
              </p>
              <p className="robotsSerie">{t("cpu.green.serie")}</p>
              <p className="robotsDescription">{t("cpu.green.description1")}</p>
              <p className="robotsDescription2">
                {t("cpu.green.description2")}
              </p>
              <div className="buttonBuy">
                <a href={t("cpu.green.link")}>
                  <p>{t("cpu.green.button")}</p>
                </a>
              </div>
            </div>
          </div>
        </Carousel>
      </div>
    </div>
  );
}

export default Cpu;
