import "./style.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { useTranslation } from "react-i18next";

function Cpu() {
  const { t, i18n } = useTranslation("common");
  return (
    <div id="game" className="game">
      <div className="titlesContainer">
        <h2>{t("game.title1")}</h2>
      </div>
      <div className="lineContainer">
        <div className="lineTitle" />
      </div>
      <div className="">
        <p className="description" style={{ textAlign: "center" }}>
          {t("game.description1")}
        </p>
      </div>
    </div>
  );
}

export default Cpu;
