import "./style.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { useTranslation } from "react-i18next";

const landing =
  "https://elasticbeanstalk-us-east-2-692605901339.s3.us-east-2.amazonaws.com/img/img/landing_corrected.gif";
const game3dimg =
  "https://elasticbeanstalk-us-east-2-692605901339.s3.us-east-2.amazonaws.com/img/img/game3d.jpg";

function Cpu() {
  const { t, i18n } = useTranslation("common");
  return (
    <div id="game3d" className="game3d">
      <div className="titlesContainer">
        <h2>
          <div>{t("game3d.title1")}</div>
          <div>{t("game3d.title2")}</div>
        </h2>
      </div>
      <div className="lineContainer">
        <div className="lineTitle" />
      </div>
      <div style={{ textAlign: "center" }}>
        <p className="description" style={{ textAlign: "center" }}>
          {t("game3d.description1")}
        </p>
        <img
          className=""
          src={landing}
          style={{
            margin: "auto",
            padding: "1.5vw",
            width: "50rem",
            maxWidth: "100%",
          }}
        />
        <br />
        <img
          className=""
          src={game3dimg}
          style={{
            margin: "auto",
            padding: "1.5vw",
            width: "50rem",
            maxWidth: "100%",
          }}
        />
      </div>
    </div>
  );
}

export default Cpu;
