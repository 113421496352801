import "./App.less";
import "../src/css/_style.scss";
import { useState } from "react";
import { Col, Layout, Menu } from "antd";
import { Animator } from "react-animators";
import Cpu from "./components/cpu/cpu";
import Footer from "./components/footer/footer";
import Home from "./components/home/home";
import { I18nextProvider } from "react-i18next";
import NavBar from "./components/navBar/navBar";
import Partnership from "./components/partnership/partnership";
import React from "react";
import Roadmap from "./components/roadmap/roadmap";
import Game from "./components/game/game";
import Apuestas from "./components/apuestas/apuestas";
import Prediccion from "./components/prediccion/prediccion";
import Robots from "./components/robots/robots";
import Team from "./components/team/team";
import Tokenomics from "./components/tokenomics/tokenomics";
import Game3d from "./components/game3d/game3d";
import common_en from "./translations/en/common-en.json";
import common_es from "./translations/en/common-es.json";
import i18next from "i18next";
import { CirclesWithBar } from "react-loader-spinner";

const { Header } = Layout;

i18next.init({
  interpolation: { escapeValue: false },
  lng: "es", // language to use
  resources: {
    en: {
      common: common_en, // 'common' is our custom namespace
    },
    es: {
      common: common_es,
    },
  }, // React already does escaping
});

const { Content } = Layout;

function App() {
  //const [loading,setLoading ] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  // setTimeout(() => {
  //   setShowLoader(false);
  // }, 12000);

  const fade = (element) => {
    var op = 1; // initial opacity
    var timer = setInterval(function () {
      if (op <= 0.1) {
        clearInterval(timer);
        element.style.display = "none";
      }
      element.style.opacity = op;
      element.style.filter = "alpha(opacity=" + op * 100 + ")";
      op -= op * 0.1;
    }, 50);
  };

  window.addEventListener("load", () => {
    //setShowLoader(false);

    setTimeout(() => {
      const loader = document.getElementById("loader");
      fade(loader);
    }, 2000);
  });

  return (
    <I18nextProvider i18n={i18next}>
      <Layout className="layout">
        <div
          id="loader"
          style={{
            background: "#0d1019",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundBlendMode: "screen",
            padding: "3rem 2rem",
            paddingTop: "6rem",
            width: "100%",
            height: "100%",
            display: showLoader ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: "0",
            zIndex: "999",
            flexDirection: "column",
          }}
        >
          <img
            style={{ mixBlendMode: "screen" }}
            alt="loader_gif"
            src="https://elasticbeanstalk-us-east-2-692605901339.s3.us-east-2.amazonaws.com/img/img/loader.gif"
          />
          <h2
            style={{
              color: "white",
              fontFamily: "Ethnocentric",
              display: "flex",
            }}
          >
            Loading ...
          </h2>
          <h2
            style={{
              color: "white",
              fontFamily: "Ethnocentric",
              display: "flex",
            }}
          >
            Please wait
          </h2>
        </div>
        <Header style={{ position: "fixed", zIndex: 1, width: "100%" }}>
          <NavBar />
        </Header>
        <Content style={{ padding: "0 50px" }}>
          <div id="home" className="site-layout-content">
            <Home />
          </div>
          <Game />
          <Apuestas />
          <Robots />
          <Cpu />
          <Prediccion />
          <Game3d />
          <Tokenomics />
          <Roadmap />
          <Team />
          <Partnership />
          <Footer />
        </Content>
      </Layout>
    </I18nextProvider>
  );
}

export default App;
